<template>
	<div>
		<Datatable
			:entries="records"
			@showData="openPatientDataModal"
			@showFiles="openFilesModal"
			@openMessages="(uuid) => $emit('openMessages', uuid)"
			@reload="reloadRecords"
		></Datatable>
		<patient-data-modal
			:record="selectedRecord"
			v-if="showDataModal"
			@close="showDataModal = false"
		></patient-data-modal>
		<files-modal
			:record="selectedRecord"
			v-if="showFilesModal"
			@close="showFilesModal = false"
		></files-modal>
	</div>
</template>

<script>
import cryptoMixin from '@/mixins/crypto.mixin';
import Socket from '@/plugins/socket';
export default {
	name: 'Lab',
	components: {
		Datatable: () => import('@/components/layout/table'),
		patientDataModal: () =>
			import('@/components/layout/modals/patient-data-modal'),
		filesModal: () => import('@/components/layout/modals/files-modal')
	},
	mixins: [cryptoMixin],
	data() {
		return {
			records: [],
			showDataModal: false,
			showFilesModal: false,
			selectedRecord: null,
			selectedRecordId: null,
			owllabCheck: null
		};
	},
	mounted() {
		this.$setTitle('Meine Aufträge');
		this.getRecords();
		if (
			this.$store.getters.getOwlLab.active &&
			window.WebSocket &&
			this.$store.getters.getOwlLab.settings.url !== null &&
			this.$store.getters.getOwlLab.settings.port !== null
		) {
			Socket.getSocket(
				this.$store.getters.getOwlLab.settings.url,
				this.$store.getters.getOwlLab.settings.port
			);
			this.checkOwlLab();
		}
	},
	beforeDestroy() {
		clearInterval(this.owllabCheck);
	},
	methods: {
		checkOwlLab() {
			Socket.getSocket(
				this.$store.getters.getOwlLab.settings.url,
				this.$store.getters.getOwlLab.settings.port
			);
			this.owllabCheck = setInterval(this.ping, 3000);
		},
		ping() {
			let isOwlLabOnline = Socket.checkSocket(
				this.$store.getters.getOwlLab.settings.url,
				this.$store.getters.getOwlLab.settings.port
			);
			this.$store.dispatch('setOwlLabStatus', { status: isOwlLabOnline });
			if (!isOwlLabOnline) {
				setTimeout(() => {
					Socket.getSocket(
						this.$store.getters.getOwlLab.settings.url,
						this.$store.getters.getOwlLab.settings.port
					);
				}, 2000);
			}
		},
		async reloadRecords() {
			this.records = [];
			await this.getRecords();
		},
		async getRecords() {
			const records = await this.$api.post(
				'/user/get_orders',
				{},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			for (let i = 0; i < records.data.length; i++) {
				const r = await this.decryptOverviewData(records.data[i]);
				this.records.push(r);
			}
		},

		async openPatientDataModal(id) {
			if (this.selectedRecordId !== id) {
				const record = this.records.filter((o) => o.UUID === id)[0];
				this.selectedRecord = await this.getExtendedData(record);
				this.selectedRecordId = id;
			}
			this.showDataModal = true;
		},

		async openFilesModal(id) {
			if (this.selectedRecordId !== id) {
				const record = this.records.filter((o) => o.UUID === id)[0];
				this.selectedRecord = await this.getExtendedData(record);
				this.selectedRecordId = id;
			}
			this.showFilesModal = true;
		},

		async getExtendedData(record) {
			const private_key = await this.$store.getters.getPrivateKey;
			const decryptkey = await this.decryptAESKey(
				this.str2ab(atob(record.aes_key_lab)),
				private_key
			);
			const iv = new Uint8Array(Object.values(JSON.parse(record.iv)));
			let extendedDataEncrypted = await this.decryptFileAES(
				iv,
				this.str2ab(atob(record.record_datum.extended)),
				decryptkey
			);
			record.extended = JSON.parse(this.ab2str(extendedDataEncrypted));
			return record;
		},

		async decryptOverviewData(data) {
			const priv = await this.$store.getters.getPrivateKey;
			const decryptkey = await this.decryptAESKey(
				this.str2ab(atob(data.aes_key_lab)),
				priv
			);
			const iv = new Uint8Array(Object.values(JSON.parse(data.iv)));
			let ov = await this.decryptFileAES(
				iv,
				this.str2ab(atob(data.record_datum.overview)),
				decryptkey
			);
			data.overview = JSON.parse(this.ab2str(ov));
			return data;
		}
	}
};
</script>

<style></style>
