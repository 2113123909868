<template>
	<div class="settings-wrapper">
		<div class="settings-tabs">
			<button
				class="w-button button"
				:class="{ active: currentPage === 'account' }"
				@click="currentPage = 'account'"
			>
				Account
			</button>
			<button
				class="w-button button"
				:class="{ active: currentPage === 'users' }"
				@click="currentPage = 'users'"
				v-if="isAdmin($store.getters.getUserRole)"
			>
				Benutzerverwaltung
			</button>
			<button
				class="w-button button"
				:class="{ active: currentPage === 'doctors' }"
				@click="currentPage = 'doctors'"
				v-if="
					$store.getters.getAppMode === 'lab' &&
					$store.getters.isClient &&
					isAdmin($store.getters.getUserRole)
				"
			>
				Ärzteverwaltung
			</button>
			<button
				class="w-button button"
				:class="{ active: currentPage === 'labs' }"
				@click="currentPage = 'labs'"
				v-if="
					$store.getters.getAppMode === 'doctor' &&
					$store.getters.isClient &&
					isAdmin($store.getters.getUserRole)
				"
			>
				Laborverwaltung
			</button>
			<button
				class="w-button button"
				:class="{ active: currentPage === 'schema' }"
				@click="currentPage = 'schema'"
				v-if="$store.getters.getAppMode === 'lab'"
			>
				Schema
			</button>
			<button
				class="w-button button"
				:class="{ active: currentPage === 'integrations' }"
				@click="currentPage = 'integrations'"
				v-if="$store.getters.getAppMode === 'lab'"
			>
				Integrationen
			</button>
		</div>
		<div class="settings-tab-content">
			<account-tab v-if="currentPage === 'account'"></account-tab>
			<user-tab v-if="currentPage === 'users'"></user-tab>
			<assoc-tab
				v-if="currentPage === 'doctors' || currentPage === 'labs'"
				:mode="currentPage"
			></assoc-tab>
			<schema-tab v-if="currentPage === 'schema'"></schema-tab>
			<integrations-tab
				v-if="currentPage === 'integrations'"
			></integrations-tab>
		</div>
	</div>
</template>

<script>
import RolesMixin from '@/mixins/roles.mixin';
export default {
	name: 'Settings',
	components: {
		accountTab: () => import('@/components/settings/tabs/account-tab.vue'),
		userTab: () => import('@/components/settings/tabs/user-tab.vue'),
		assocTab: () => import('@/components/settings/tabs/assoc-tab.vue'),
		schemaTab: () => import('@/components/settings/tabs/schema-tab.vue'),
		integrationsTab: () =>
			import('@/components/settings/tabs/integrations-tab.vue')
	},
	mixins: [RolesMixin],
	data() {
		return {
			currentPage: 'account'
		};
	},
	mounted() {
		this.$setTitle('Einstellungen');
	},
	methods: {}
};
</script>
<style lang="scss">
.settings-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-height: 50vh;
	align-items: center;
	.settings-tabs {
		margin-top: 5rem;
		padding: 2rem 0;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		.button {
			margin: 0;
			background-color: rgba(165, 165, 165, 0.29);
			color: $dark-grey;
			font-size: 0.85rem;
			&.active {
				background-color: rgb(90, 141, 185);
				color: white;
			}
		}
	}
	.settings-tab-content {
		width: 75%;
		margin-bottom: 30px;
		border-radius: 10px;
		min-height: 10vh;
		background-color: #f8f8f8;
		box-shadow: 0 0 20px -12px #394150;
	}
}
</style>
