var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-wrapper"},[_c('div',{staticClass:"settings-tabs"},[_c('button',{staticClass:"w-button button",class:{ active: _vm.currentPage === 'account' },on:{"click":function($event){_vm.currentPage = 'account'}}},[_vm._v(" Account ")]),(_vm.isAdmin(_vm.$store.getters.getUserRole))?_c('button',{staticClass:"w-button button",class:{ active: _vm.currentPage === 'users' },on:{"click":function($event){_vm.currentPage = 'users'}}},[_vm._v(" Benutzerverwaltung ")]):_vm._e(),(
				_vm.$store.getters.getAppMode === 'lab' &&
				_vm.$store.getters.isClient &&
				_vm.isAdmin(_vm.$store.getters.getUserRole)
			)?_c('button',{staticClass:"w-button button",class:{ active: _vm.currentPage === 'doctors' },on:{"click":function($event){_vm.currentPage = 'doctors'}}},[_vm._v(" Ärzteverwaltung ")]):_vm._e(),(
				_vm.$store.getters.getAppMode === 'doctor' &&
				_vm.$store.getters.isClient &&
				_vm.isAdmin(_vm.$store.getters.getUserRole)
			)?_c('button',{staticClass:"w-button button",class:{ active: _vm.currentPage === 'labs' },on:{"click":function($event){_vm.currentPage = 'labs'}}},[_vm._v(" Laborverwaltung ")]):_vm._e(),(_vm.$store.getters.getAppMode === 'lab')?_c('button',{staticClass:"w-button button",class:{ active: _vm.currentPage === 'schema' },on:{"click":function($event){_vm.currentPage = 'schema'}}},[_vm._v(" Schema ")]):_vm._e(),(_vm.$store.getters.getAppMode === 'lab')?_c('button',{staticClass:"w-button button",class:{ active: _vm.currentPage === 'integrations' },on:{"click":function($event){_vm.currentPage = 'integrations'}}},[_vm._v(" Integrationen ")]):_vm._e()]),_c('div',{staticClass:"settings-tab-content"},[(_vm.currentPage === 'account')?_c('account-tab'):_vm._e(),(_vm.currentPage === 'users')?_c('user-tab'):_vm._e(),(_vm.currentPage === 'doctors' || _vm.currentPage === 'labs')?_c('assoc-tab',{attrs:{"mode":_vm.currentPage}}):_vm._e(),(_vm.currentPage === 'schema')?_c('schema-tab'):_vm._e(),(_vm.currentPage === 'integrations')?_c('integrations-tab'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }